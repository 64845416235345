import React from 'react';
import styled from 'styled-components';
import { Link, animateScroll as scroll } from 'react-scroll';
import Logo from './assets/imgs/ArboreumLogoDark.svg';

const AppHeader = styled.header`
	box-shadow: 0 4px 13px -3px rgba(0, 0, 0, 0.10196);
	position: fixed;
	background-color: #fff;
	width: 100%;
	z-index: 1000;
`;

const HeaderContainer = styled.div`
	margin: 0 auto;
	max-width: 1440px;
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
`;

const LogoImage = styled.img`
	height: 30px;
	width: auto;
	cursor: pointer;
	margin: 15px 20px;
	@media (max-width: 420px) {
		height: 25px;
	}
`;

const NavContainer = styled.div`
	display: flex;
	font-size: 18px;

	a {
		color: #9098a6;
		text-decoration: none;
		margin: 0 20px;
		cursor: pointer;
	}
	a:hover {
		color: #ed135a;
	}

	transition: all 0.3s;

	@media (max-width: 880px) {
		opacity: ${(props: { navOpen: boolean }) => (props.navOpen ? '1' : '0')};
		height: ${(props: { navOpen: boolean }) =>
			props.navOpen ? 'unset' : '0px'};
		padding: ${(props: { navOpen: boolean }) =>
			props.navOpen ? '10px' : '0px'};
		overflow: hidden;
		width: 100%;
		flex-direction: column;
		align-items: center;

		a {
			padding: 10px;
		}

		a:not(:last-child) {
			padding-bottom: 10px;
		}
	}
`;

const NavButton = styled.div`
	height: 25px;
	width: 30px;
	margin: 0 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;

	${(props: { navOpen: boolean }) =>
		props.navOpen &&
		`
		div:first-child {
			transform: rotate(45deg);
		}

		div:nth-child(2) {
			transform: translateX(200px);
		}

		div:last-child {
			transform: rotate(-45deg);
		}
	`}

	@media (min-width: 881px) {
		display: none;
	}
`;

const Bar = styled.div`
	height: 2px;
	width: 100%;
	margin: 1px;
	transform-origin: left;
	background-color: #333;
	transition: all 0.3s;
`;

const Map: React.FunctionComponent<{}> = () => {
	const [navOpen, setNavOpen] = React.useState(false);
	return (
		<AppHeader>
			<HeaderContainer>
				<LogoImage
					src={Logo}
					alt="Arboreum Logo"
					onClick={() => {
						scroll.scrollToTop();
					}}
				/>
				<NavButton
					onClick={() => {
						setNavOpen(!navOpen);
					}}
					navOpen={navOpen}
				>
					<Bar />
					<Bar />
					<Bar />
				</NavButton>
				<NavContainer navOpen={navOpen}>
					<Link
						to="section1"
						onClick={() => setNavOpen(false)}
						spy={true}
						smooth={true}
						duration={500}
					>
						About
					</Link>
					<Link
						to="section2"
						onClick={() => setNavOpen(false)}
						spy={true}
						smooth={true}
						duration={500}
					>
						Features
					</Link>
					<Link
						to="section3"
						onClick={() => setNavOpen(false)}
						spy={true}
						smooth={true}
						duration={500}
					>
						Products
					</Link>
					<Link
						to="section4"
						onClick={() => setNavOpen(false)}
						spy={true}
						smooth={true}
						duration={500}
					>
						Team
					</Link>
					<a
						href="https://revolution.arboreum.dev"
						onBlur={() => setNavOpen(false)}
					>
						Vision
					</a>
				</NavContainer>
			</HeaderContainer>
		</AppHeader>
	);
};

export default Map;
