import React from 'react';
import styled from 'styled-components';

interface announcementData {
	company: string;
	logo: string;
	link: string;
}

const DivContainer = styled.div`
	width: 100%;
	font-size: 24px;
	color: #fff;
	margin-top: 75px;
	background-color: #1a2943;
	padding-top: 20px;
`;

const Div = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	//   padding: 20px;
	background-color: #1a2943;
	flex-wrap: wrap;
`;

const P = styled.p`
	margin: 0 15px 0 0;
`;

const DivBorder = styled.div`
	height: 10px;
	background-image: linear-gradient(
		90deg,
		#ed145a 0%,
		#bc5e6b 25%,
		#009893 50%,
		#55b359 75%,
		#73bf44 100%
	);
`;

const Map: React.FunctionComponent<{}> = () => {
	return (
		<>
			<DivContainer>
				<Div>
					<P>Supported with grants from</P>
				</Div>
				<Div>
					<a href="https://ciie.co/" style={{ textAlign: 'center' }}>
						<img
							src="./assets/imgs/CIIECO_IIMA_White-01.png"
							style={{
								width: '72%',
							}}
							alt="CIIE.CO"
						/>
					</a>
					<a href="https://www.algorand.com">
						<img
							src="./assets/imgs/algorand_full_logo_white.svg"
							style={{
								width: '220px',
								height: '95px',
							}}
							alt="Algorand"
						/>
					</a>
					<a href="https://celo.org">
						<img
							src="./assets/imgs/Celo Logo Color Reverse.svg"
							style={{
								width: '200px',
								height: '80px',
							}}
							alt="Celo"
						/>
					</a>
					<a href="https://consensys.net">
						<img
							src="./assets/imgs/consensys-logo-text.svg"
							style={{
								width: '300px',
								height: '55px',
								filter: 'invert(1)',
							}}
							alt="Consensys"
						/>
					</a>
				</Div>
			</DivContainer>
			<DivBorder />
		</>
	);
};

export default Map;
