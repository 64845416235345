import React, { useState } from "react";
import styled from "styled-components";
import Icon from "./assets/imgs/fractalIcon.svg";

interface H3Props {
  align: string;
}

const DivContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding: 80px 20px 100px 20px;
  @media (max-width: 420px) {
    padding-bottom: 50px;
  }
`;

const TitleContainer = styled.div`
  margin: 0 auto 75px auto;
  text-align: center;
  max-width: 720px;
  @media (max-width: 420px) {
    margin-bottom: 25px;
  }
`;

const LogoImage = styled.img`
  height: 42px;
  width: auto;
  margin: auto;
`;

const IconConatiner = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const Map: React.FunctionComponent<{}> = () => {
  // eslint-disable-next-line
  const [windowWidthValue, setWindowWidthValue] = useState(window.innerWidth);
  window.onresize = () => {
    setWindowWidthValue(window.innerWidth);
  };
  return (
    <DivContainer>
      <IconConatiner>
        <LogoImage src={Icon} alt="Fractal Lending Icon" />
      </IconConatiner>
      <TitleContainer>
        <h1>Scalable, Anchor-led working-capital solutions for SMEs</h1>
        <h3>
          We build and manage credit networks of lenders, borrowers, and trusting supporters.
          Our technology mitigates risk for lenders whilst leveraging supporter trust to improve credit access for borrowers.
        </h3>
      </TitleContainer>
    </DivContainer>
  );
};

export default Map;
