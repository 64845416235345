import React from 'react';
import styled from 'styled-components';
import Logo from './assets/imgs/ArboreumLogoLight.svg';
import SMData from './assets/data/sm.json';

interface SMType {
	socialMedia: string;
	icon: string;
	link: string;
}

const AppFooter = styled.footer`
	background-color: #1a2943;
	color: #fff;
`;

const FooterContainer = styled.div`
	margin: 0 auto;
	max-width: 1440px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 20px;
`;

const LogoImage = styled.img`
	height: 20px;
	width: auto;
	margin-bottom: 10px;
`;

const SMIcon = styled.img`
	height: 36px;
	width: auto;
	margin: 0 10px;
`;

const DivContainer = styled.div`
	margin: 0 auto;
	max-width: 1272px;
	padding: 40px 20px;
`;

const H2 = styled.h2`
	font-weight: normal;
	margin-bottom: 0px;
	font-family: 'Raleway', sans-serif;
	text-align: center;
`;

const H3 = styled.h3`
	margin-top: 5px;
	margin-bottom: 0;
	text-align: center;
	font-family: 'Roboto', sans-serif;
`;

const Button = styled.div`
	display: flex;
	font-size: 16px;
	padding: 10px 30px;
	border-radius: 50px;
	color: #ed135a;
	background-color: #fff;
	border: 1px solid #ed135a;
	width: fit-content;
	box-shadow: 0 0px 10px -3px rgba(0, 0, 0, 0.4);
	margin: auto;
	margin-top: 30px;
	transition: all 0.5s ease;
	&:hover {
		color: #fff;
		background-color: #ed135a;
	}
`;

const P = styled.p`
	font-size: 14px;
	color: #fff;
	opacity: 0.7;
	margin: 0;
`;
const Line = styled.div`
	height: 1px;
	background-color: #fff;
	opacity: 0.2;
`;

const CardContainer = styled.div`
	margin: 0px auto 40px auto;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	min-width: 320px;
	flex: 1 1 100%;
	flex-wrap: wrap;
	max-width: 960px;
	color: #000;
`;

const Card = styled.div`
	margin-top: 25px;
	max-width: 460px;
	font-size: 20px;
`;

const CardContent = styled.div`
	padding: 30px 20px 10px 20px;
	background-color: #fff;
	font-size: 18px;
	align-items: stretch;
	box-shadow: 0 0px 13px -3px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	transition: all 0.5s ease;
	height: calc(100% - 40px);
	&:hover {
		box-shadow: 0 0px 13px 0px rgba(0, 0, 0, 0.3);
	}
`;

const CardTitle = styled.div`
	padding: 10px 20px;
	display: flex;
	font-size: 20px;
	color: #ed145a;
	border: 2px solid #ed145a;
	border-radius: 50px;
	width: max-content;
	margin: 0 auto;
`;

const Map: React.FunctionComponent<{}> = () => {
	const SM = SMData.map((d: SMType, i: number) => {
		return (
			<a href={d.link} target="_blank" key={i} rel="noopener noreferrer">
				<SMIcon src={d.icon} alt={d.socialMedia} />
			</a>
		);
	});
	return (
		<AppFooter>
			<DivContainer>
				<H2>Learn more</H2>
				<CardContainer>
					<Card>
						<a
							href="https://forms.gle/4hEFxZjqnFKzjsQi6"
							target="_blank"
							rel="noopener noreferrer"
						>
							<CardContent>
								<CardTitle align="left">Request Pitch Deck</CardTitle>
								<p>
									Learn more about the challenges we face, our novel solution,
									and our overall business strategy
								</p>
							</CardContent>
						</a>
					</Card>
					<Card>
						<a
							href="https://forms.gle/W6VDH65VrBCcSMu77"
							target="_blank"
							rel="noopener noreferrer"
						>
							<CardContent>
								<CardTitle align="left">Request Whitepaper</CardTitle>
								<p>
									Learn more about the underlying mathematical concepts that
									power our solution
								</p>
							</CardContent>
						</a>
					</Card>
				</CardContainer>
				<H2>Ready to partner with us?</H2>
				<H3>Get in touch to find out how we can help you.</H3>
				<a
					href="mailto:enquiries@arboreum.dev?subject=Getting In Touch With Arboreum"
					target="_blank"
					rel="noopener noreferrer"
				>
					<Button>Contact Us</Button>
				</a>
			</DivContainer>
			<Line />
			<FooterContainer>
				<div>
					<LogoImage src={Logo} alt="Arboreum Logo" />
					<P>
						© {new Date().getFullYear()} Equal Squirrels Technologies Pvt Ltd.
						All rights reserved.
					</P>
				</div>
				<div>{SM}</div>
			</FooterContainer>
		</AppFooter>
	);
};

export default Map;
